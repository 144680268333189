import { useParams, Link } from "react-router-dom";

function UserInformation() {
  const { userName } = useParams();

  return (
    <section>
      <div className="thanksTitle">
      <h1>User Information</h1>
      <p>your generated username: {userName}</p>
      <Link to="/">Back to Home</Link>
      </div>
    </section>
  );
}

export default UserInformation;
