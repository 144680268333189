import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import '../index.css';

const Item = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useCart();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const item = location?.state?.item;

  if (!item) {
    navigate('/jackets'); 
    return null;
  }

  const addToCart = () => {
    dispatch({ type: 'ADD_TO_CART', payload: item });
  };

  return (
    <>
      <section className='storeFront-Container'>
        <div className='itemPage-container'>
          <div className='storeItem-content'>
            <div className='item-content'>
              <img src={"." + item.image} alt={item.name} />
            </div>
          </div>

          <div className='itemInfo-content'>
            <div className='item-info'>
              <h1 className='item-title'>{item.name}</h1>
              <p>{item.description}</p>
            </div>

            <div className='size-buttons'>
              {item.sizes.map((size, index) => (
                <button key={index} className="circle-button">{size}</button>
              ))}
            </div>

            <div className='checkout-box'>
              <p className='item-price'>${item.price.toFixed(2)}</p>
              <button className="cart-button" onClick={addToCart}>
                ADD TO CART
              </button>            
            </div>
          </div>
        </div>
      </section>
      <Outlet />
    </>
  );
};

export default Item;
