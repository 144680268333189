import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import "../index.css";

function UserForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const goToHomepage = (e) => {
    e.preventDefault();
    console.log("working");
    navigate('/Homepage', { state: formData });
  };

  return (
    <>
      <section id='loginPage'>
        <div id='title'>
          <img id="logo" src="./1x/logo.png" alt="logo" />
          <h1>Forgot Password?</h1>
          <p>Enter your email address and we'll send you a link to reset your password.</p>
          <br/>
        </div>

        <div id='resetpassword-form'>
          <label>
            <input type="text" id="input" name="email" value={formData.email} onChange={handleChange} placeholder='Email'/>
          </label>
        </div>

        <div className='login-buttons'>
          <input type="submit" id="submit" value="Send Link" onClick={goToHomepage}/>
          <Link to="/">Sign In</Link>
          <Link to="/signup">Sign Up</Link>
        </div>
      </section>

      {/* 
      <section id='loginPage-title'>
      </section> 
      */}
    </>
  );
}

export default UserForm;
