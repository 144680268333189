import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import { useUser } from '../context/UserContext';
import '../index.css';

function SignIn() {
  const navigate = useNavigate();
  const { setUsername } = useUser();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const goToHomepage = (e) => {
    e.preventDefault();
    setUsername(formData.username);
    navigate('/Homepage');
  };

// The rendered form component
    return (    
    <>         
    <section id='loginPage'>

        <div id='title'>
        <img id="logo" src="./1x/logo.png" alt="logo" />
        <h1>Sign in</h1>

        <label>
        <input type="text" id="input" name="username" value={formData.username} onChange={handleChange} placeholder='Username'/>
        </label>

        <label>
        <input type="password" id="input" name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
        </label>

        </div>
    
    <div className='login-buttons'>
    <input type="submit" id="submit" value="Sign in" onClick={goToHomepage}/>
    <Link to="/forgotpassword">Forgot password?</Link>
    <Link to="/signup">Sign up</Link>
    </div>

    <div className='login-title'>
        <h1>Get Vintage</h1>
        <p>"Throwbacks aren't just for the music; in our vintage store, every find is a timeless encore!" </p>
        </div>


    </section>
{/* 
    <section id='loginPage-title'>



    </section> */}

    </>
);
}

export default SignIn;
