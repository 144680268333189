import React from 'react';
import "../index.css";
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const goToJackets = (e) => {
    e.preventDefault();
    console.log("working");
    navigate('/Jackets');
  };

  const goToHoodies = (e) => {
    e.preventDefault();
    console.log("working");
    navigate('/Hoodies');
  };

  return (
    <section className='storeFront'>
      <div className='storeFront-Container'>
        <div className='storeFront-video'>
          <h1>GET VINTAGE</h1>
          <video className='store-video' autoPlay loop>
            <source src="storefootage.mp4" type="video/mp4"/>
          </video>
        </div>

        <div className='storeFront-title'>
          <div className='store-title'>
            <h1>Black Friday Sale</h1>
          </div>
        </div>

        <div className='storeFront-content' onClick={goToJackets}>
          <div className='store-content'>
            <img src="./1x/item1.png" alt="bapehoodie" />
          </div>
          <h1 id='float-text'>JACKETS</h1>
        </div>

        <div className='storeFront-content' onClick={goToJackets}>
          <div className='store-content'>
            <img src="./1x/sale.png" alt="sale" />
          </div>
        </div>

        <div className='storeFront-content' onClick={goToHoodies}>
          <div className='store-content'>
            <img src="./1x/item2.png" alt="bapehoodie" />
          </div>
          <h1 id='float-text'>HOODIES</h1>
        </div>

        <div className='storeFront-footer'>
          <div className='store-footer'>
            <p>© 2021 Get Vintage</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePage;
