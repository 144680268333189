import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CartProvider } from './context/CartContext';
import { UserProvider } from './context/UserContext';
import ReactDOM from "react-dom";

import Layout from "./pages/Layout";
import HomePage from "./pages/HomePage";
import ItemPage from "./pages/ItemPage";
// import Blogs from "./pages/Blogs";
// import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

import Cart from "./pages/Cart";
import CheckoutComplete from "./pages/CheckoutComplete";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import ForgotPassword from "./pages/ForgotPassword";
import SignUp from "./pages/SignUp";
// import Projects from "./pages/Projects";
// import Aliens from "./pages/Aliens";
// import Zerkblatt from "./pages/Zerkblatt";
// import Zargon from "./pages/Zargon";
// import Who from "./pages/Who";
// import ItemPage from "./pages/ItemPage";
import Item from "./pages/Item";
// import Blog1 from "./pages/blog1";
// import Blog2 from "./pages/blog2";
// import Blog3 from "./pages/blog3";
// import Blog4 from "./pages/blog4";
import UserInformation from "./pages/UserInformation";
import SignIn from "./pages/SignIn";
import Jackets from "./pages/Jackets";
import Tees from "./pages/Tees";
import Shoes from "./pages/Shoes";
import Hoodies from "./pages/Hoodies";
// import Mens from "./pages/Mens";
// import Womens from "./pages/Womens";


export default function App() {
  const [namer, setNamer] = useState('');

  const handleChange = (e) => {
    console.log(e.target.value);
    setNamer(e.target.value);
  }

  return (
    <BrowserRouter>
    <UserProvider>
    <CartProvider>
      <Routes>
      <Route index element={<SignIn handleChange={(e) => handleChange(e)} />} />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route path="signUp" element={<SignUp />} />

        <Route path="/" element={<Layout />}>
        <Route path="homePage" element={<HomePage/>}/>
        
        <Route path="itempage/*" element={<ItemPage />}>
  {/* ItemPage specific routes */}
  <Route path="Item" element={<Item />} />
</Route>

<Route path="jackets" element={<Jackets />}>
  {/* Jackets specific routes */}
  <Route path="Item" element={<Item />} />
</Route>

<Route path="tees" element={<Tees />}>
  {/* Tees specific routes */}
  <Route path="Item" element={<Item />} />
</Route>

<Route path="shoes" element={<Shoes />}>
  {/* Sweaters specific routes */}
  <Route path="Item" element={<Item />} />
</Route>

<Route path="hoodies" element={<Hoodies />}>
  {/* Hoodies specific routes */}
  <Route path="Item" element={<Item />} />
</Route>






          <Route path="cart/*" element={<Cart />}>
            </Route>

            <Route path="checkoutComplete" element={<CheckoutComplete />} />
            
            <Route path="profile" element={<Profile />} >
            <Route path="editProfile" element={<EditProfile />} />
            </Route>

          {/* <Route path="blog/*" element={<Blogs />}>
            <Route path="blog1" element={<Blog1 />} />
            <Route path="blog2" element={<Blog2 />} />
            <Route path="blog3" element={<Blog3 />} />
            <Route path="blog4" element={<Blog4 />} />
          </Route> */}

          <Route path="/user/:userName" element={<UserInformation />} />
          {/* <Route path="contact" element={<Contact />} /> */}
          {/* <Route path="projects/:projectId" element={<Projects />} /> */}
          {/* <Route path="aliens/*" element={<Aliens />}> */}
            {/* <Route path="zerkblatt" element={<Zerkblatt />} /> */}
            {/* <Route path="zargon" element={<Zargon />} /> */}
            {/* <Route path="*" element={<Who />} /> */}
          {/* </Route> */}
          <Route path="*" element={<NoPage />} exact />
        </Route>
      </Routes>
      </CartProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
