import React from 'react';
import { Link } from 'react-router-dom';

import "../index.css";

const CheckoutComplete = () => {
    return (
        <>
        <section className='storeFront-Container'>
        <div className='checkout-Container'>
        <div className='checkout-item-complete'>
        <h1 className='checkout-title'>THANK YOU FOR YOUR PURCHASE</h1>
        <p className='checkout-text'>Your order will be shipped within 3-5 business days. You will receive an email confirmation with tracking information once your order ships. If you have any questions, please contact us at postolanj@gmail.com</p>
        </div>
        <Link to='/homepage'>
            <div className='checkout-button'>Back To Shopping..
            </div>
        </Link>
        </div>
        </section>
    </>
    );
}

export default CheckoutComplete;
