import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import '../index.css';

const Cart = () => {
  const { state } = useCart();
  const subtotal = state.items.reduce((acc, item) => acc + item.price, 0);
  const tax = 0.15 * subtotal;
  const navigate = useNavigate();
  const total = subtotal + 10 + tax;

  const handleCheckout = () => {
    if (state.items.length === 0) {
      alert("Your cart is empty. Add items to proceed to checkout.");
    } else {
      navigate('/checkoutComplete');
    }
  };

  return (
    <>
      <section className='storeFront-Container'>
        <div className='cart-container'>
          <div className='checkoutItem-content'>
            {state.items.map((item, index) => (
              <div className='checkout-item' key={index}>
                <div className='product'>
                  <img src={item.image} alt={item.name} />
                </div>
                <div className='product-info'>
                  <p>{item.name}</p>
                  <p>${item.price.toFixed(2)}</p>
                  <a href="#">Remove</a>
                </div>
              </div>
            ))}
          </div>

          <div className='itemInfo-content'>
            <div className='info-content'>
              <h1>Order Summary</h1>
              <div className='total'>
                <p>Subtotal: ${subtotal.toFixed(2)}</p>
                <p>Shipping: $10.00</p>
                <p>Tax (15%): ${tax.toFixed(2)}</p>
                <p>Total: ${total.toFixed(2)}</p>
              </div>
              <div className='promo-codes-box'>
                <p>Enter Promo Code</p>
                <div className='promo-codes'>
                  <input type="text" id="input" name="username" placeholder='Promo Code' />
                  <button>Apply</button>
                </div>
              </div>
              <div className='checkout-button' onClick={handleCheckout}>
                CHECKOUT
              </div>
            </div>
          </div>
        </div>
      </section>

      <Outlet />
    </>
  );
};

export default Cart;
