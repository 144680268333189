import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useCart } from "../context/CartContext"; 
import "../index.css";

const Layout = () => {
  const { state } = useCart(); 

  return (
    <>
      <nav className="nav">
        <div className="nav-links">
          <Link to="/Jackets">Jackets</Link>
          <Link to="/Tees">Tees</Link>
          <Link to="/Hoodies">Hoodies</Link>
        </div>

        <div className="nav-logo">
          <Link to="/Homepage">
            <div className="logo">
              <img src="./1x/logo.png" alt="logo" />
            </div>
          </Link>
        </div>

        <div className="nav-links">
        <Link to="/Shoes">Shoes</Link>

          <Link to="/Cart">
            Cart {state.items.length > 0 && <span>{state.items.length}</span>}
          </Link>
          <Link to="/profile">Profile</Link>
        </div>
      </nav>

      <Outlet />
    </>
  );
};

export default Layout;
