import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import "../index.css";

const Shoes = () => {
  const navigate = useNavigate();
  const [shoes, setShoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/data/shoes.json') 
      .then(response => response.json())
      .then(data => setShoes(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const goToItem = (item) => {
    navigate('item', { state: { item } });
  };

  if (loading) {
    return <p>Loading...</p>; 
  }

  if (error) {
    return <p>Error loading jacket data: {error.message}</p>; 
  }

  return (
    <>
      <Outlet />
      <section className='storeFront'>
        <div className='storeFront-Container'>
          <div className='storeFront-title'>
            <div className='store-title-item'>
              <h1>Shoes</h1>
            </div>
          </div>

          {shoes.map(shoe => (
            <div key={shoe.id} className='storeFront-content' onClick={() => goToItem(shoe)}>
              <div className='store-content'>
                <img src={"." + shoe.image} alt={shoe.name} />
              </div>
              <div className='checkout'>
                <h1>{shoe.name}</h1>
                <p>${shoe.price.toFixed(2)}</p>
                <p>{shoe.sizes.join(' ')}</p>
              </div>
            </div>
          ))}

          <div className='storeFront-footer'>
            <div className='store-footer'>
            © 2021 Get Vintage
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Shoes;
