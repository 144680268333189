import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import '../index.css';

const Tees = () => {
  const navigate = useNavigate();
  const [tees, setTees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/data/tees.json')
      .then(response => response.json())
      .then(data => setTees(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const goToItem = (item) => {

    navigate('item', { state: { item } });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading tee data: {error.message}</p>; 
  }

  return (
    <>
      <Outlet />
      <section className='storeFront'>
        <div className='storeFront-Container'>
          <div className='storeFront-title'>
            <div className='store-title-item'>
              <h1>Tees</h1>
            </div>
          </div>

          {tees.map(tee => (
            <div key={tee.id} className='storeFront-content' onClick={() => goToItem(tee)}>
              <div className='store-content'>
                <img src={tee.image} alt={tee.name} />
              </div>
              <div className='checkout'>
                <h1>{tee.name}</h1>
                <p>${tee.price.toFixed(2)}</p>
                <p>{tee.sizes.join(' ')}</p>
              </div>
            </div>
          ))}

          <div className='storeFront-footer'>
            <div className='store-footer'>
            © 2021 Get Vintage
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tees;
