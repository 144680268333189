import React from 'react';
import { Outlet } from 'react-router-dom';
import "../index.css";
import { useNavigate } from 'react-router-dom';


const ItemPage = () => {
    const navigate = useNavigate(); 

    const goToItem = (e) => {
    e.preventDefault();

    console.log("working");

      navigate('item');
    };

    return (
    <>
    <Outlet />
    <section className='storeFront'>
        <div className='storeFront-Container'>

        <div className='storeFront-title'>
        <div className='store-title'>
        Title
        </div>
        </div>

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>       

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  
        
        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-content' onClick={goToItem}>
        <div className='store-content'>
        <img src="./1x/item1.png" alt="bapehoodie" />
        </div>
        <div className='checkout'>
        <p>12.99</p>
        </div>
        </div>  

        <div className='storeFront-footer'>
        <div className='store-footer'>
        Banner
        </div>
        </div>
    </div>

    </section>
    </>
);
}


export default ItemPage;
