// EditProfile.js
import React, { useState } from 'react';
import { useUser } from '../context/UserContext';
import { Link } from 'react-router-dom';
import '../index.css';

const EditProfile = () => {
    const { state, setUsername } = useUser();
    const [newUsername, setNewUsername] = useState('');

    const handleChange = (e) => {
    setNewUsername(e.target.value);
    };

    const handleSave = () => {
    setUsername(newUsername);
    };

    return (
        <>
        <section className='storeFront-Container'>
            
            <div className='editprofile-content'>
                <div className='editprofile-title'>
                    <h1>EDIT PROFILE</h1>
                </div>

            <div className='editprofile-form'>
                <input type="text" value={newUsername} onChange={handleChange} placeholder="Enter Username" />
                <button onClick={handleSave}>Save</button>
                <Link to='/profile'>
                <button>Cancel</button>
                </Link>
            </div>

            </div>

        </section>
    </>
);
};

export default EditProfile;
