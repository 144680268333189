import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useUser } from '../context/UserContext'; 

const Profile = () => {
        const { state } = useUser();
        const username = state.username;

return (
        <>
        <Outlet />
        <section className='storeFront-Container'>
                <div className='profile-container'>
                <div className='profileItem-content'>
                <div className='item-content'>
                <h1>{username ? `WELCOME ${username}` : 'WELCOME'}</h1>
                </div>
                <Link to='/'>
                <p>Sign Out</p>
                </Link>
                </div>
                <div className='profile-content'>
                <Link to='editprofile'>
                <div className='profile-option'>Edit Profile</div>
                </Link>
                <div className='profile-option'>Payment Information</div>
                <div className='profile-option'>Purchase History</div>
                </div>
        </div>
        </section>
        </>
        );
};

export default Profile;
