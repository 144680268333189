import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import "../index.css";

    function UserForm() {
    const navigate = useNavigate();

    // State to manage form data
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        email2: '',
        password: '',
        password2: '',
    });

    const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
};


    const goToHomepage = (e) => {
        e.preventDefault();
    
        console.log("working");
    
        navigate('/Homepage', { state: formData });
        };

    return (    
    <>         
    <section id='loginPage'>

        <div id='title'>
        <img id="logo" src="./1x/logo.png" alt="logo" />
        <h1>Sign up</h1>
        <p>Already have an account? <Link to="/">Sign in</Link></p>
        </div>

        <div id='signup-form'>

        <label>
        <input type="text" id="input" name="username" value={formData.username} onChange={handleChange} placeholder='Username' />
        </label>

        <label>
        <input type="text" id="input" name="email" value={formData.email} onChange={handleChange} placeholder='Email'/>
        </label>

        <label>
        <input type="text" id="input" name="email2" value={formData.email2} onChange={handleChange} placeholder='Confirm Email'/>
        </label>
        
        <label>
        <input type="password" id="input" name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
        </label>

        <label>
        <input type="password" id="input" name="password2" value={formData.password2} onChange={handleChange} placeholder='Confirm Password' />
        </label>

        <input type="submit" id="submit" value="Sign up" onClick={goToHomepage}/>


        </div>


    </section>
{/* 
    <section id='loginPage-title'>



    </section> */}

    </>
);
}

export default UserForm;
